module.exports = {
  OPTION_TYPES: {
    DEFAULT: 0,
    DISCOUNT: 1,
    DISCOUNT_PREMIUM: 2,
  },

  OPTION_TYPES_OPTIONS: [
    { value: 0, text: 'Default' },
    { value: 1, text: 'Discount' },
    { value: 2, text: 'Discount Premium' },
  ],

  REFERRAL_NETWORK: {
    key: 'REFERRAL_NETWORK',
    default_data: [
      {
        key: "ZALO",
        image: "https://stc-zaloprofile.zdn.vn/pc/v1/images/zalo_sharelogo.png",
        referral_id: "1433117008488711236"
      },
      {
        key: "MESSENGER",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/2048px-Facebook_Messenger_logo_2020.svg.png",
        referral_id: "104222198430912"
      }]
  },

  MODULE_CATEGORY: 'system',

  DISCOUNT_OPTION_USED_CONFIG: {
    key: 'DISCOUNT_OPTION_USED',
    default_data: 'discount_option1',
    category: 'iap_popup'
  }
}