<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Iap Popup" class="mb-3" />
      </b-col>
      <b-col md="2 text-right">
        <b-button @click="openModal" variant="primary">+ Add Iap Popup</b-button>
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col md="10">
            <h2 class="mb-2">Iap Popup Management</h2>
          </b-col>
          <b-col md="2">
            <b-button variant="primary" size="s" @click="openDiscountOptionUsedConfigModal">Discount Option Used</b-button>
          </b-col>
        </b-row>
        <form-generator :schema="filter_schema" :model="filter" style="width: 100%" />
        <b-row>
          <b-table striped hover responsive :items="lists" :fields="headerTables">
            <template #cell(stt)="data">{{ data.index + 1 }}</template>
            <template #cell(title)="data">{{ getTitle(data.item) }}</template>
            <template #cell()="data">{{ data.value }}</template>
            <template #cell(action)="data">
              <b-button
                variant="gradient-warning"
                style="margin-right: 5px"
                class="btn-icon"
                @click="editIapPopup(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                @click="confirmDelete(data.item._id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </b-table>
        </b-row>
      </section>
    </b-media>
    <iap-popup-modal
      :data-update="dataUpdate"
      @create="create"
      @update="updateIapPopup"
      ref="iapPopupModal"
    />
    <discount-option-used-config-modal ref="discountOptionUsedConfigModal" />
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import IapPopupModal from "./_components/IapPopupModal.vue";
import DiscountOptionUsedConfigModal from './_components/DiscountOptionUsedConfigModal.vue';
export default {
  components: {
    IapPopupModal,
    DiscountOptionUsedConfigModal
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
      lists: [],
      dataUpdate: null,
      headerTables: [
        "stt",
        {
          key: "title",
          label: "Title",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "language",
          label: "Language",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      filter_schema: [
        { cols: 2, fields: [{ label: 'Language', field: 'language', input_type: 'select', options: this.$store.getters['language/language_options']}] 
        },{
          cols: 4, fields: [{label: "Type", field: 'type', input_type: 'text'}]
        }
      ],
      filter: {},
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
    filter: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getTitle(item) {
      if (!item.is_multiple_title) {
        return item.title
      }

      return item.multiple_title.titles && item.multiple_title.titles.length ? item.multiple_title.titles.join(' ') : ''
    },
    openModal() {
      this.dataUpdate = null;
      this.$refs.iapPopupModal.openModal();
    },
    async getList() {
      let response = await service.getList({
        query: JSON.stringify(this.filter),
        page: this.current_page,
        limit: this.items_perpage,
      });
      if (response.data.type === "DATA") {
        this.lists = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    async deleteIapPopup(id) {
      await service.delete({ id: id });
      this.getList();
    },
    async create(iap_popup) {
      let response = await service.create(iap_popup);
      if (response.data.type == "DATA") {
        this.getList();
      }
    },
    editIapPopup(iap_popup) {
      this.dataUpdate = iap_popup;
      this.$refs.iapPopupModal.openModal();
    },
    async updateIapPopup(iap_popup) {
      await service.update({ data: JSON.stringify(iap_popup) });
      await this.getList();
      this.dataUpdate = null;
    },
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteIapPopup(id);
          }
        });
    },

    openDiscountOptionUsedConfigModal() {
      this.$refs.discountOptionUsedConfigModal.openUpdateModal();
    },
  },
};
</script>
